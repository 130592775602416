import Btn from '@/components/Btn/Btn.vue';
import DataTable from '@/components/DataTable/DataTable.vue';
import roleFilter from '@/filters/role';
import DELETE_USER_MUTATION from '@/graphql/mutations/deleteUser.gql';
import USERS_QUERY from '@/graphql/queries/users.gql';
import ConfirmationModal from '@/components/ConfirmationModal/ConfirmationModal.vue';
import eventHub from '@/utils/eventHub';
import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
import ROLES_WITH_PERMISSIONS from '@/graphql/queries/rolesWithPermissions.gql';
import accessByPermissions from '@/services/userPermissions';
import PERMISSIONS from '@/enums/permissions';
import Modal from '@/components/Modal/Modal.vue';
import EditUser from '@/components/ManageUsers/EditUser/EditUser.vue';

export default {
  name: 'UserList',
  components: { Btn, DataTable, ConfirmationModal, Modal, EditUser },
  props: {
    users: { type: Array, default: [] },
    loading: { type: Boolean },
  },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-only',
    },
    rolesWithPermissions: {
      query: ROLES_WITH_PERMISSIONS,
      fetchPolicy: 'cache-first',
    },
  },
  data: () => ({
    DELETE_USER_MUTATION,
    rolesWithPermissions: null,
    currentUser: null,
    email: '',
    valid: true,
    headers: [
      {
        text: 'User Name',
        value: 'username',
        type: 'text',
        dataType: 'string',
        width: '100px',
      },
      { text: 'Client', value: 'clientName', type: 'select', dataType: 'string', width: '100px' },
      { text: 'Email', value: 'email', type: 'text', dataType: 'string', width: '200px' },
      { text: 'Role', value: 'displayedRole', type: 'select', dataType: 'string', width: '100px' },
      { text: 'Flag', value: 'flag', type: 'select', dataType: 'string', width: '100px' },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
        width: '53px',
        fixed: true,
        permissions: [PERMISSIONS.EDIT_USER, PERMISSIONS.DELETE_USER],
      },
    ],
  }),
  computed: {
    displayedItems() {
      return this.users && this.rolesWithPermissions
        ? this.users.map(user => {
            const rPermissions = this.rolesWithPermissions.find(p => p.name === user.role.name);
            return {
              ...user,
              displayedRole: roleFilter(user.role.name),
              clientName: (user.client && user.client.name) || '',
              flag:
                rPermissions &&
                (rPermissions.permissions.length !== user.permissions.length ||
                  user.permissions.some(p => !rPermissions.permissions.some(rP => p.key === rP.key)))
                  ? 'Custom'
                  : 'Default',
            };
          })
        : [];
    },
    displayedHeaders() {
      return this.headers.filter(h => !h.permissions || h.permissions.some(p => accessByPermissions(p, this.currentUser)));
    },
    showEditBtn() {
      return accessByPermissions(PERMISSIONS.EDIT_USER, this.currentUser);
    },
    showDeleteBtn() {
      return accessByPermissions(PERMISSIONS.DELETE_USER, this.currentUser);
    },
    showPermissionsSection() {
      return accessByPermissions(PERMISSIONS.CHANGE_USER_PERMISSIONS, this.currentUser);
    },
  },
  methods: {
    onDeleteUser(item) {
      return (store, { data: { deleteUser } }) => {
        if (deleteUser) {
          // Read the data from our cache for this query.
          const data = store.readQuery({
            query: USERS_QUERY,
          });
          data.users = data.users.filter(user => {
            return user.id !== item.id;
          });
          store.writeQuery({
            query: USERS_QUERY,
            data,
          });
          eventHub.$emit('show-snackbar', { color: 'success', text: 'User was deleted from the system successfully!' });
        }
      };
    },
  },
};
