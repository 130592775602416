import Btn from '@/components/Btn/Btn.vue';
import USERS_QUERY from '@/graphql/queries/users.gql';
import UserList from '@/components/ManageUsers/UserList/UserList.vue';

export default {
  name: 'ManageUsers',
  components: { Btn, UserList },
  data: () => ({
    USERS_QUERY,
  }),
};
