import UserForm from '@/components/ManageUsers/UserForm/UserForm.vue';
import ADD_USER_MUTATION from '@/graphql/mutations/addUser.gql';
import USERS_QUERY from '@/graphql/queries/users.gql';
import eventHub from '@/utils/eventHub';

export default {
  name: 'AddUser',
  components: { UserForm },
  data: () => ({
    ADD_USER_MUTATION,
    user: { username: null, email: null, role: null, client: null, permissions: null },
  }),
  methods: {
    updateUsers(store, { data: { addUser } }) {
      if (addUser) {
        // Read the data from our cache for this query.
        const data = store.readQuery({
          query: USERS_QUERY,
        });
        data.users.unshift(addUser);
        store.writeQuery({
          query: USERS_QUERY,
          data,
        });
        eventHub.$emit('show-snackbar', {
          color: 'success',
          text: `User ${this.user.username} was added into the system successfully!`,
        });
      } else {
        eventHub.$emit('show-snackbar', {
          color: 'error',
          text: `Error! Something went wrong... Please try later!`,
        });
      }
    },
  },
};
