var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('data-table',{attrs:{"items":_vm.displayedItems,"headers":_vm.displayedHeaders,"height":"611px","item-key":"id","loading":_vm.loading,"noDataText":"No Users"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"table-actions-bar"},[_c('modal',{attrs:{"title":"Edit User","max-width":_vm.showPermissionsSection ? 780 : 480},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.showEditBtn)?_c('v-icon',_vm._g(_vm._b({staticClass:"action edit mr-2",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("far fa-edit")]):_vm._e()]}},{key:"default",fn:function(ref){
var close = ref.close;
return [_c('edit-user',{attrs:{"data":item},on:{"done":close}})]}}],null,true)}),(_vm.showDeleteBtn)?_c('confirmation-modal',{attrs:{"mutation":_vm.DELETE_USER_MUTATION,"variables":{ id: item.id },"title":"Delete User","message":"Are you sure you want to delete the User?","ok-btn":{ label: 'Delete' },"update":_vm.onDeleteUser(item)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"action",attrs:{"small":""}},'v-icon',{ attrs: attrs },false),on),[_vm._v(" far fa-trash-alt ")])]}}],null,true)}):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }