import CURRENT_USER_QUERY from '@/graphql/queries/currentUser.gql';
import ManageUsers from '@/components/ManageUsers/ManageUsers.vue';
import Btn from '@/components/Btn/Btn.vue';
import Modal from '@/components/Modal/Modal.vue';
import AddUser from '@/components/ManageUsers/AddUser/AddUser.vue';
import accessByPermissions from '@/services/userPermissions';
import PERMISSIONS from '@/enums/permissions';
export default {
  components: { AddUser, Modal, ManageUsers, Btn },
  apollo: {
    currentUser: {
      query: CURRENT_USER_QUERY,
      fetchPolicy: 'cache-only',
    },
  },
  data: () => ({
    currentUser: null,
  }),
  computed: {
    showAddUserBtn() {
      return accessByPermissions(PERMISSIONS.ADD_USER, this.currentUser);
    },
    showPermissionsSection() {
      return accessByPermissions(PERMISSIONS.CHANGE_USER_PERMISSIONS, this.currentUser);
    },
  },
};
